import feedbackConstants from '../views/candidatePage/feedbackConstants';

const comment = (comment) => comment ? `${feedbackConstants.TOPIC_COMMENT} ${comment}` : '';

const generateFeedback = function(feedbacks) {
  return feedbacks.map(feedback => {
    return `          *   *   * 
${feedback.interview.type} ${feedbackConstants.INTERVIEWER_TEMPLATE} ${
  feedback.interview.interviewer
}
${feedbackConstants.DATE_OF_THE_INTERVIEW} ${feedback.interview.date}
${feedbackConstants.GENERAL_COMMENT} ${feedback.generalComment}
${feedbackConstants.TOPICS_FEEDBACK}
${feedback.feedbackTopics.map(topic => {
      if(topic.topic.name === "English") {
        return `\r\n ${comment(topic.comment)} \r\n  English Level: ${topic.grade} \r\n`

      } else {
        return `\r\n ${topic.template} \r\n ${comment(topic.comment)} \r\n`
      }

    })} \r\n`;
  });
};

export default generateFeedback;
