<template>
  <div>
    <div class="d-flex justify-space-between justify-sm-end align-center">
      <v-btn
        class="ml-1 theme-color"
        rounded
        v-if="feedbacks.length"
        @click.stop="isFinalDialogFeedbackOpen = true"
        >Final Feedback
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            elevation="3"
            class="ma-3 theme-color"
            v-bind="attrs"
            v-on="on"
            @click.stop="isDialogInterviewOpen = true"
          >
            <v-icon size="20px">fa-calendar-plus</v-icon>
          </v-btn>
        </template>
        <span>Add a new Interview</span>
      </v-tooltip>
    </div>
    <CandidateInfo
      :candidateId="this.id"
      @onEditItem="handleEditItem"
      @onEditCandidate='handleEditCandidate'
      @onDeleteItem="handleDeleteItem"
      @onDeleteRecommendation = "handleDeleteRecommendation"
    />
    <CandidateDialog
      :candidateId="this.id"
      :data="editedCandidate"
      :open="isDialogCandidateOpen"
      @onSubmit="handleDialogCandidateSubmit"
      @onClose="handleDialogCandidateClose"
    />
    <InterviewDialog
      :candidateId="this.id"
      :data="editedItem"
      :open="isDialogInterviewOpen"
      @onClose="handleDialogInterviewClose"
    />
    <FeedbackDialog
      :candidateId="this.id"
      :open="isFinalDialogFeedbackOpen"
      @onClose="handleDialogFeedbackClose"
    />
  </div>
</template>

<script>
import CandidateInfo from './candidateInfo';
import CandidateDialog from '../candidates/dialog';
import InterviewDialog from './dialogInterview';
import FeedbackDialog from './dialogFinalFeedback';
export default {
  name: 'index',
  props: ['id'],
  components: {
    CandidateInfo,
    InterviewDialog,
    FeedbackDialog,
    CandidateDialog
  },
  data: () => ({
    isDialogInterviewOpen: false,
    isFinalDialogFeedbackOpen: false,
    editedItem: null,
    editedCandidate: null,
    isDialogCandidateOpen: false,
  }),
  methods: {
    handleDialogInterviewClose() {
      this.isDialogInterviewOpen = false;
      this.$data.editedItem = null;
    },
    handleDialogFeedbackClose() {
      this.$data.isFinalDialogFeedbackOpen = false;
    },
    handleEditItem(item) {
      this.$data.editedItem = { ...item };
      this.$data.isDialogInterviewOpen = true;
    },
    handleDialogCandidateClose() {
      this.$data.isDialogCandidateOpen = false;
      this.$data.editedCandidate = null;
    },
    handleEditCandidate(item) {
      this.$data.editedCandidate = { ...item };
      this.$data.isDialogCandidateOpen = true;
    },
    async handleDeleteItem(item) {
      if (window.confirm('are you sure? ')) {
        await this.$store.dispatch(`interviews/delete`, item.id);
        await this.$store.dispatch('interviews/load');
        await this.$store.dispatch('feedbacks/load');
      }
    },
    async handleDialogCandidateSubmit(data) {
      await this.$store.dispatch('candidates/edit', data);
      await this.$store.dispatch('candidates/load');
    },
    async handleDeleteRecommendation(item) {
      if (window.confirm('are you sure? ')) {
        await this.$store.dispatch(`recommendations/delete`, item.id);
        await this.$store.dispatch('recommendations/load');
      }
    }
  },
  computed: {
    feedbacks() {
      return this.$store.state.feedbacks.list.filter(
        feedback => feedback.interview.candidateId === +this.$props.id
      );
    }
  },
  created() {
    this.$store.dispatch('candidates/load');
    this.$store.dispatch('interviews/load');
    this.$store.dispatch('feedbacks/load');
    this.$store.dispatch('positions/load');

  }
};
</script>
