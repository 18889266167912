<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" sm="7">
        <v-card  v-if="candidate">
          <v-card-text>
            <div>Candidate:</div>
            <div class='d-flex align-center '>
            <p class="text-h4 text--primary">{{ candidate.firstName }} {{ candidate.lastName }}</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  size="16"
                  class="mx-2 fa-xs edit"
                  @click.stop="handleEditCandidate(candidate)"
                >fa-edit
                </v-icon>
              </template>
              <span>Edit Candidate</span>
            </v-tooltip>
            </div>
            <div class='d-flex'>Position:</div>
            <div class='d-flex justify-space-between align-center'>
              <p class="text--primary">
                {{ candidate.position.name }}
              </p>
              <v-btn
                class='linkedin-btn'
                rounded
                outlined
                v-if='candidate.linkedinProfile'
                :href='candidate.linkedinProfile'
                target="_blank"
                @click.stop="isFinalDialogFeedbackOpen = true"
              >Linkedin Profile
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <FeedbacksForm :candidateId="this.candidateId" class="d-none d-sm-flex flex-sm-column" />
      </v-col>
      <v-col cols="12" sm="5">
        <v-card  v-if='interviews.length' class="mx-auto ">
          <div class="ml-4 pt-2 pt-sm-4">Interviews:</div>
          <v-card-text v-for="interview in interviews" :key="interview.id" class="py-0">
            <div class="d-flex justify-space-between">
              <p class="pt-1 pt-sm-3 my-1">Interviewer: {{ interview.interviewer }}</p>
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="16"
                      class="mx-2 "
                      @click.stop="handleEdit(interview)"
                      fab
                      >fa-edit
                    </v-icon>
                  </template>
                  <span>Edit Interview</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="20"
                      class="mx-2 delete"
                      @click.stop="handleDelete(interview)"
                      fab
                      >fa-times
                    </v-icon>
                  </template>
                  <span>Delete Interview</span>
                </v-tooltip>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <p class="mb-1 mb-sm-3">Date: {{ interview.date }}</p>
              <p class="mb-1 mb-sm-3">Type: {{ interview.type }}</p>
            </div>
            <v-divider></v-divider>
            <v-card-actions></v-card-actions>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text  disabled class="text-justify  mt-3" >
            <div>No interviews yet, add one first</div>
          </v-card-text>
        </v-card>
        <v-card v-if='recommendations.length' class="mt-4">
          <div class="ml-4 pt-3 pt-sm-4">Candidate`s recommendations:</div>
          <v-card-text v-for="recommendation in recommendations" :key="recommendation.id" class="py-0">
          <div class="d-flex justify-space-between">
            <p class="pt-1 pt-sm-3 my-1">Interviewer: {{recommendation.feedback.interview.interviewer}}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p class="mb-1 mb-sm-3">Recommendation to hire: {{ recommendation.recommendation }}</p>
          </div>
          <v-divider></v-divider>
          <v-card-actions></v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <FeedbacksForm :candidateId="this.candidateId" class="d-flex flex-column d-sm-none" />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import FeedbacksForm from './feedbacksForm';


export default {
  props: {
    onEditItem: Function,
    onDeleteItem: Function,
    onEditCandidate: Function,
    onDeleteRecommendation: Function,
    onClick: Function,
    candidateId: String
  },
  components: {
    FeedbacksForm,
  },
  name: 'CandidateInfo',
  methods: {
    handleEdit(data) {
      this.$emit('onEditItem', data);
    },
    handleEditCandidate(data) {
      this.$emit('onEditCandidate', data);
    },
    handleDelete(data) {
      this.$emit('onDeleteItem', data);
    },
    handleDeleteRecommendation(data) {
      this.$emit('onDeleteRecommendation', data);
    }
  },
  computed: {
    candidate() {
      return this.$store.state.candidates.list.find(candidate => candidate.id === +this.candidateId);
    },
    interviews() {
      return this.$store.state.interviews.list.filter(
        interview => interview.candidateId === +this.candidateId
      );
    },
    recommendations() {
      return this.$store.state.recommendations.list.filter(
        recommendation => recommendation.feedback.interview.candidateId === +this.candidateId
      )
    }
  },
  created() {
    return this.$store.dispatch("recommendations/load")
  }
};
</script>

<style scoped>
.edit {
  margin-top: -15px;
}
.linkedin-btn {
  margin-top: -25px;
}
</style>


