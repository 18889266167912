<template>
  <v-container class="px-0 pt-0">
    <v-card elevation="0">
      <v-card-text> Tags</v-card-text>

      <template>
        <v-container class="d-flex flex-column justify-space-around py-0">
          <div class="search">
            <v-autocomplete
              class="ml-2"
              :items="filteredTags()"
              :item-text="tagName"
              item-value="id"
              item-color="secondary"
              return-object
              flat
              outlined
              rounded
              hide-no-data
              hide-details
              label="Search tags"
              append-icon=""
              multiple
              v-model="addedTags"
            >
            </v-autocomplete>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  elevation="3"
                  class="mx-2 theme-color"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="isDialogOpen = true"
                >
                  <v-icon>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Add a new Tag</span>
            </v-tooltip>
          </div>

          <v-container class="tagsDragContainer ma-0 pt-0">
            <draggable class="list-group " :list="addedTags" :group="{ name: 'tags' }">
              <v-chip
                color="primary"
                class="list-group-item ma-1"
                v-for="tag in addedTags"
                :key="tag.name"
              >
                {{ tag.name }}
                <v-icon class="mx-1 delete" fab @click.end="removeTag(tag.id)">
                  fa-times
                </v-icon>
              </v-chip>
            </draggable>
          </v-container>

          <v-container class="tagsDropContainer ma-0">
            <v-icon small class="mr-2">
              fa-user-plus
            </v-icon>
            <span>Candidate knows well:</span>

            <draggable class="list-group" :list="positive" group="tags" @change="handleChange">
              <v-chip
                color="primary"
                class="list-group-item ma-1"
                v-for="tag in positive"
                :key="tag.id"
              >
                {{ tag.name }}
              </v-chip>
            </draggable>
          </v-container>

          <v-container class="tagsDropContainer mx-0">
            <v-icon small class="mr-2">
              fa-user-minus
            </v-icon>
            <span>Candidate lacks knowledge in:</span>

            <draggable class="list-group" :list="negative" group="tags" @change="handleChange">
              <v-chip
                color="primary"
                class="list-group-item mx-1 "
                v-for="tag in negative"
                :key="tag.id"
              >
                {{ tag.name }}
              </v-chip>
            </draggable>
          </v-container>
        </v-container>
      </template>
    </v-card>
    <Dialog :open="isDialogOpen" width="500px" @onClose="handleDialogClose" />
  </v-container>
</template>

<script>
import _ from 'lodash';
import draggable from 'vuedraggable';
import Dialog from '../views/adminTags/dialog.vue';

const getDefaultState = () => ({
  feedbackTopicTags: [],
  positive: [],
  negative: [],
  selectedTags: [],
  addedTags: [],
  isDialogOpen: false
});

export default {
  components: {
    draggable,
    Dialog
  },
  props: {
    topic: Object
  },
  data: () => getDefaultState(),
  created() {
    this.$store.dispatch('tags/load');
    this.$data.addedTags = this.list;
  },
  computed: {
    list() {
      return this.topic.tags;
    },
    allTags() {
      return this.$store.state.tags.list;
    }
  },
  methods: {
    handleChange() {
      this.feedbackTopicTags = [];

      if (this.positive.length > 0) {
        this.positive.forEach(tag =>
          this.feedbackTopicTags.push({ tagId: tag.id, isPositive: true })
        );
      }
      if (this.negative.length > 0) {
        this.negative.forEach(tag =>
          this.feedbackTopicTags.push({ tagId: tag.id, isPositive: false })
        );
      }

      this.$emit('onChoose', { data: this.feedbackTopicTags, id: this.topic.id });
    },
    tagName(tag) {
      return tag.name;
    },
    filteredTags() {
      const filteredTags = this.allTags.filter(
        tag => !this.addedTags.find(added => added.name === tag.name && tag.id === added.id)
      );
      return filteredTags;
    },
    removeTag(id) {
      this.addedTags = _.filter(this.addedTags, t => t.id !== id);
      this.positive = _.filter(this.positive, t => t.id !== id);
      this.negative = _.filter(this.negative, t => t.id !== id);
    },
    handleDialogClose() {
      this.$data.isDialogOpen = false;
    }
  }
};
</script>

<style scoped>
.search {
  min-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagsDragContainer {
  height: auto;
  width: 100%;
  margin: 5px 15px;
}
.tagsDropContainer {
  height: auto;
  min-height: 70px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  margin: 5px 0px;
}
div.container.tagsDropContainer:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
}
</style>
