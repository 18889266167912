<template>
  <div>
    <v-dialog @click:outside="handleClose" :value="open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Interview</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Interviewer"
                v-model="form.interviewer"
                :rules="[rules.required, rules.counter, rules.input]"
              ></v-text-field>
              <v-date-picker :min="now" dark v-model="form.date"></v-date-picker>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select :items="['Hr', 'Technical']" label="type" v-model="form.type"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="handleClose">Close</v-btn>
          <v-btn text rounded @click="handleDialogSubmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const getDefaultState = () => ({
  form: {
    interviewer: '',
    date: '',
    type: ''
  },
  rules: {
    required: value => !!value || 'Required',
    counter: value => value.length >= 2 || 'Min 2 characters',
    input: value => {
      const pattern = /^[a-zA-Z\s]+$/;
      return pattern.test(value) || 'Invalid input';
    }
  },
  now: new Date().toISOString().slice(0, 10)
});
export default {
  props: {
    open: Boolean,
    data: Object,
    onSubmit: Function,
    onClose: Function,
    candidateId: String
  },
  name: 'InterviewDialog',

  data: () => getDefaultState(),
  updated() {
    if (this.$props.data) this.$data.form = this.$props.data;
  },
  methods: {
    handleClose() {
      this.$emit('onClose', this.$data.form);
      Object.assign(this.$data, getDefaultState());
    },

    async handleDialogSubmit() {
      if (!this.$data.form.interviewer || !this.$data.form.date || !this.$data.form.type) {
        alert('fill all rows');
        return false;
      }
      if (this.$data.form.id) {
        await this.$store.dispatch('interviews/edit', this.$data.form);
        await this.$store.dispatch('feedbacks/load');
      } else {
        this.$data.form.candidateId = this.$props.candidateId;
        await this.$store.dispatch('interviews/create', this.$data.form);
      }
      await this.$store.dispatch('interviews/load');
      this.handleClose();
    }
  }
};
</script>
