<template>
  <div>
    <v-dialog @click:outside="handleClose" :value="open" max-width="600px">
      <v-card height="100%">
        <v-card-title>
          <span class="text-h5">Final Feedback</span>
        </v-card-title>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-if="candidate.length"
            :items="templates"
            item-text="text"
            v-model="template"
            label="Choose template"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-card-text v-if="candidate.length">
          Feedback for {{ candidate[0].firstName }} {{ candidate[0].lastName }}
          <v-textarea
            id="textarea-final"
            height="500px"
            solo
            hide-details
            ref="textToCopy"
            :value="feedbacksToShow"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn text rounded @click="copyText">Copy</v-btn>
          <v-btn text rounded @click="handleClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import generateFeedback from '../../utils/feedbackHelper';

const getDefaultState = () => ({
  template: ''
});
export default {
  props: {
    open: Boolean,
    onClose: Function,
    candidateId: String
  },
  name: 'dialogFeedback',
  data: () => getDefaultState(),
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector('textarea');
      textToCopy.select();
      document.execCommand('copy');
      alert('Feedback copied');
      this.handleClose();
    }
  },
  created() {
    this.$store.dispatch('feedbacks/load');
    this.$store.dispatch('templates/load');
    this.$store.dispatch('candidates/load');
  },
  computed: {
    candidate() {
      return this.$store.state.candidates.list.filter(
        candidate => candidate.id === +this.candidateId
      );
    },
    interviews() {
      return this.$store.state.interviews.list.filter(
        interview => interview.candidateId === +this.candidateId
      );
    },
    templates() {
      const candidate = this.$store.state.candidates.list.find(
        candidate => candidate.id === +this.candidateId
      );
      return this.$store.state.templates.list.map(template =>
        template.text.replaceAll('$', candidate.firstName)
      );
    },
    feedbacksTemplate() {
      const feedbacks = this.$store.state.feedbacks.list.filter(
        feedback => feedback.interview.candidateId === +this.candidateId
      );
      return generateFeedback(feedbacks)
        .join('\r\n')
        .replaceAll(',', '');
    },
    feedbacksToShow() {
      return `${this.template}
      ${this.feedbacksTemplate}`;
    },

  }
};
</script>

<style>
#textarea-final {
  font-size: 1.1em;
}
</style>
