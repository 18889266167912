<template>
  <div>
    <v-card class="mt-3 feedback-block">
      <v-card-title class="text-h6 text--primary">
        Intermediate feedback
      </v-card-title>
      <v-textarea
        height="350px"
        solo
        hide-details
        name="intermediate feedback"
        ref="textToCopy"
        :value="feedback"
      ></v-textarea>
      <v-card-actions>
        <v-btn text outlined @click="copyText">Copy</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import generateFeedback from '../../utils/feedbackHelper';

export default {
  name: 'IntermediateFeedback',
  props: {
    feedbackId: Number
  },
  components: {},

  data: () => ({}),
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector('textarea');
      textToCopy.select();
      document.execCommand('copy');
    }
  },
  mounted() {
    this.$store.dispatch('feedbacks/load');
  },
  computed: {
    feedback() {
      const feedback = this.$store.state.feedbacks.list.filter(
        feedback => feedback.id === this.feedbackId
      );
      return generateFeedback(feedback).join('\r\n').replaceAll(',', '');
    }
  }
};
</script>

<style scoped>
.feedback-block {
  max-width: 100%;
}
</style>
