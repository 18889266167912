var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[(_vm.candidate)?_c('v-card',[_c('v-card-text',[_c('div',[_vm._v("Candidate:")]),_c('div',{staticClass:"d-flex align-center "},[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(_vm._s(_vm.candidate.firstName)+" "+_vm._s(_vm.candidate.lastName))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 fa-xs edit",attrs:{"size":"16"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditCandidate(_vm.candidate)}}},'v-icon',attrs,false),on),[_vm._v("fa-edit ")])]}}],null,false,867774701)},[_c('span',[_vm._v("Edit Candidate")])])],1),_c('div',{staticClass:"d-flex"},[_vm._v("Position:")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.candidate.position.name)+" ")]),(_vm.candidate.linkedinProfile)?_c('v-btn',{staticClass:"linkedin-btn",attrs:{"rounded":"","outlined":"","href":_vm.candidate.linkedinProfile,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();_vm.isFinalDialogFeedbackOpen = true}}},[_vm._v("Linkedin Profile ")]):_vm._e()],1)])],1):_vm._e(),_c('FeedbacksForm',{staticClass:"d-none d-sm-flex flex-sm-column",attrs:{"candidateId":this.candidateId}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[(_vm.interviews.length)?_c('v-card',{staticClass:"mx-auto "},[_c('div',{staticClass:"ml-4 pt-2 pt-sm-4"},[_vm._v("Interviews:")]),_vm._l((_vm.interviews),function(interview){return _c('v-card-text',{key:interview.id,staticClass:"py-0"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"pt-1 pt-sm-3 my-1"},[_vm._v("Interviewer: "+_vm._s(interview.interviewer))]),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 ",attrs:{"size":"16","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(interview)}}},'v-icon',attrs,false),on),[_vm._v("fa-edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit Interview")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 delete",attrs:{"size":"20","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(interview)}}},'v-icon',attrs,false),on),[_vm._v("fa-times ")])]}}],null,true)},[_c('span',[_vm._v("Delete Interview")])])],1)]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"mb-1 mb-sm-3"},[_vm._v("Date: "+_vm._s(interview.date))]),_c('p',{staticClass:"mb-1 mb-sm-3"},[_vm._v("Type: "+_vm._s(interview.type))])]),_c('v-divider'),_c('v-card-actions')],1)})],2):_c('v-card',[_c('v-card-text',{staticClass:"text-justify  mt-3",attrs:{"disabled":""}},[_c('div',[_vm._v("No interviews yet, add one first")])])],1),(_vm.recommendations.length)?_c('v-card',{staticClass:"mt-4"},[_c('div',{staticClass:"ml-4 pt-3 pt-sm-4"},[_vm._v("Candidate`s recommendations:")]),_vm._l((_vm.recommendations),function(recommendation){return _c('v-card-text',{key:recommendation.id,staticClass:"py-0"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"pt-1 pt-sm-3 my-1"},[_vm._v("Interviewer: "+_vm._s(recommendation.feedback.interview.interviewer))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"mb-1 mb-sm-3"},[_vm._v("Recommendation to hire: "+_vm._s(recommendation.recommendation))])]),_c('v-divider'),_c('v-card-actions')],1)})],2):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('FeedbacksForm',{staticClass:"d-flex flex-column d-sm-none",attrs:{"candidateId":this.candidateId}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }