<template>
  <div class="form mt-5">
    <div v-for="interview in interviews" :key="interview.id" class="mb-3">
      <v-card>
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="text-h6 text-sm-h6 text-break pb-0 pb-sm-3 ml-2">
            {{ interview.type }} Feedback by {{ interview.interviewer }}
          </v-card-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :class="[
                  feedbackExists(interview.id) ? 'my-2  xsCustomPadding delete' : 'icon-hide'
                ]"
                @click="deleteFeedback(interview.id)"
                size="20"
                >fa-times
              </v-icon>
            </template>
            <span>Clears feedback</span>
          </v-tooltip>
        </div>
        <div class="d-flex flex-wrap justify-space-between px-5 pb-3">
          <v-btn
            v-if="interviews.length"
            class="fb-open-btn mr-1 mt-1"
            outlined
            rounded
            @click.stop="handleClick(interview)"
            text
            :disabled="feedbackExists(interview.id)"
          >
            {{
              interview.isOpen
                ? 'Close'
                : feedbackExists(interview.id)
                ? 'Feedback created'
                : 'Open'
            }}
            <v-icon id="white-icon" :class="[feedbackExists(interview.id) ? 'mx-2' : 'icon-hide']"
              >fa-check
            </v-icon>
          </v-btn>
          <v-btn
            v-if="feedbackExists(interview.id)"
            class="mt-1"
            outlined
            rounded
            text
            @click.stop="handleSeparateFeedbackClick(interview)"
          >
            <v-tooltip bottom v-if="!interview.isIntermediateOpen">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="!interview.isIntermediateOpen" v-bind="attrs" v-on="on" size="20">
                  fa-envelope-open-text
                </v-icon>
              </template>
              <span>Check intermediate feedback</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  fa-window-close
                </v-icon>
              </template>
              <span>Close feedback</span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-card>
      <IntermediateFeedback
        v-if="interview.isIntermediateOpen"
        :feedbackId="intermediateFeedbackId"
      />

      <Feedback
        v-if="interview.isOpen"
        :interviewId="interview.id"
        :candidateId="interview.candidateId"
        @feedbackCreated="
          isOpen => {
            interview.isOpen = isOpen;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import Feedback from './Feedback';
import IntermediateFeedback from './intermediateFeedback';

export default {
  name: 'FeedbacksForm',
  props: {
    candidateId: String
  },
  components: {
    Feedback,
    IntermediateFeedback
  },
  data() {
    return {
      isOpen: false,
      isIntermediateOpen: false,
      isDialogFeedbackOpen: false,
      intermediateFeedbackId: null
    };
  },
  methods: {
    handleClick(interview) {
      interview.isOpen = !interview.isOpen;
    },
    handleDialogFeedbackClose() {
      this.$data.isDialogFeedbackOpen = false;
    },
    handleSeparateFeedbackClick(interview) {
      this.interviews
        .filter(int => int.id !== interview.id)
        .forEach(interview => (interview.isIntermediateOpen = false));
      const feedback = this.$store.state.feedbacks.list.find(
        feedback => feedback.interview.id === interview.id
      );
      this.intermediateFeedbackId = feedback.id;
      interview.isIntermediateOpen = !interview.isIntermediateOpen;
    },

    feedbackExists(id) {
      if (this.$store.state.feedbacks.list.filter(feedback => feedback.interview.id == id).length) {
        return true;
      }
      return false;
    },

    async deleteFeedback(interviewId) {
      const feedbacktoDelete = this.$store.state.feedbacks.list.find(
        feedback => feedback.interview.id === interviewId
      );
      const interview = this.interviews.find(interview => interview.id === interviewId);
      interview.isIntermediateOpen = false;
      await this.$store.dispatch('feedbacks/delete', feedbacktoDelete.id);
      await this.$store.dispatch('feedbacks/load');
      await this.$store.dispatch('recommendations/load');
    }
  },
  computed: {
    interviews() {
      return this.$store.state.interviews.list.filter(
        interview => interview.candidateId == this.candidateId
      );
    }
  }
};
</script>

<style scoped>
.form {
  width: 100%;
}
#white-icon {
  color: #ffffff !important;
}
.icon-hide {
  display: none;
}
.fb-open-btn {
  max-width: 90%;
}
@media (max-width: 360px) {
  .xsCustomPadding {
    margin-right: 25px;
  }
}
@media screen and (min-width: 361px) {
  .xsCustomPadding {
    margin-right: 45px;
  }
}
</style>
