const feedbackConstants = {
  INTERVIEWER_TEMPLATE: 'Feedback by',
  DATE_OF_THE_INTERVIEW: 'Date of the interview: ',
  GENERAL_COMMENT: 'General Feedback comment: ',
  TOPICS_FEEDBACK: 'Feedback on topics: ',
  TOPIC_NAME: 'Topic name: ',
  GRADE: 'Topic\'s Grade: ',
  TOPIC_COMMENT: 'Comment: ',
  TAGS_FEEDBACK: 'Feedback on tags: ',
  TAG_NAME: 'Tag name: ',
  TAG_TEXT: 'Does the candidate know this: ',
  CANDIDATE_KNOW: 'You know this tag well',
  CANDIDATE_DOESNT_KNOW: 'You should improve this'
}



export default feedbackConstants;

