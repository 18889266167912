/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div>
    <v-card class="mt-3 feedback-block">
      <div class="d-flex justify-space-between align-center px-0 py-1 text--primary">
        <v-card-text class="text-subtitle-1 text-sm-h6  py-0">
          Your Feedback
        </v-card-text>
        <div class="d-flex justify-start">
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  elevation="3"
                  class="mx-2 theme-color"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleClick"
                >
                  Assesment
                </v-btn>
              </template>
              <span>Rate the Topics</span>
            </v-tooltip>
          </v-card-actions>
        </div>
      </div>
      <Dialog :open="isDialogOpen" width="500px" height="500px" @onClose="handleDialogClose" />
      <div class="d-flex justify-start flex-wrap mt-3">
        <v-card-text class="mb-3">
          * - required field
        </v-card-text>
        <div v-if="!isTopicOpen" class="search">
          <v-autocomplete
            class="ml-2"
            :items="filteredTopics()"
            :item-text="topicName"
            item-value="id"
            item-color="secondary"
            return-object
            flat
            outlined
            rounded
            hide-no-data
            hide-details
            label="Search topics"
            append-icon=""
            multiple
            v-model="addedTopics"
          >
          </v-autocomplete>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                elevation="3"
                class="mx-2 theme-color"
                v-bind="attrs"
                v-on="on"
                @click.stop="isDialogOpen = true"
              >
                <v-icon>fa-plus</v-icon>
              </v-btn>
            </template>
            <span>Add a new Topic</span>
          </v-tooltip>
        </div>
        <div class="topic-container">
          <div v-for="topic in addedTopics" :key="topic.id" class="topic-container">
            <v-chip color="primary" class="my-1 mx-3 ma-sm-3">{{ topic.name }}</v-chip>
            <v-icon class="mx-2 delete" fab @click="removeTopic(topic.id)">
              fa-times
            </v-icon>
            <v-card v-if="isTopicOpen" width="100%">
              <v-card-text class="py-0">*</v-card-text>
              <v-rating
                hover
                class="d-flex justify-center"
                length="5"
                size="25"
                v-model="topic.grade"
                background-color="purple lighten-3"
              ></v-rating>
              <TagsDragAndDrop
                :topic="topic"
                @onChoose="value => (topic.feedbackTopicTags = value.data)"
              />
              <div class="mx-3 my-0 ma-sm-3">
                <v-text-field v-model="topic.comment" label="Topic Comment"></v-text-field>
              </div>
            </v-card>
          </div>
          <v-card elevation="0">
            <div class="d-flex english-select">
              <v-chip color="primary" class="my-1 mx-3 ma-sm-3">English</v-chip>
              <v-select
                :items="englishLevel"
                :item-text="englishLevel.text"
                :item-value="englishLevel.value"
                label="*English level"
                v-model="english.grade"
                class="select pt-0 pt-sm-2 "
                hide-details
              ></v-select>
            </div>
            <div class="mx-3 my-0 ma-sm-3 px-2">
              <v-text-field v-model="english.comment" label="English Comment"></v-text-field>
            </div>
          </v-card>
        </div>
        <v-text-field
          class="mx-4"
          v-model="generalComment"
          label="*General Feedback`s Comment"
        ></v-text-field>
      </div>
      <v-select
        :items="candidatesRates"
        label="*Hire recommendation"
        v-model="candidateRate"
        class="mx-4"
        hide-details
      ></v-select>
      <v-card-actions>
        <v-btn @click.end="create" rounded class="theme-color">Create</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import Dialog from './dialogTopic';
import TagsDragAndDrop from '../../components/tagsDragAndDrop.vue';
export default {
  name: 'Feedback',
  props: {
    candidateId: Number,
    interviewId: Number,
    isInterviewOpened: Boolean
  },
  components: {
    Dialog,
    TagsDragAndDrop
  },
  data: () => ({
    isTopicOpen: false,
    isDialogOpen: false,
    slider: { label: 'Topic grade', color: 'secondary' },
    addedTopics: [],
    generalComment: '',
    dataToSend: {
      interviewId: null,
      generalComment: '',
      feedbackTopics: null
    },
    selectedTopics: [],
    english: {
      grade: null,
      comment: ''
    },
    englishLevel: [
      { text: 'A1', value: 0 },
      { text: 'A2', value: 1 },
      { text: 'B1', value: 2 },
      { text: 'B2', value: 3 },
      { text: 'C1', value: 4 },
      { text: 'C2', value: 5 }
    ],
    candidateRate: '',
    candidatesRates: [
      'Strongly recommend',
      'Rather recommend than not recommend',
      'Rather NOT recommend than recommend',
      'Not recommend',
      'Strongly NOT recommend to hire'
    ]
  }),
  methods: {
    handleClick() {
      this.isTopicOpen = !this.isTopicOpen;
    },
    handleDialogClose() {
      this.$data.isDialogOpen = false;
    },
    filteredTopics() {
      const filteredTopics = this.allTopics.filter(
        topic => !this.addedTopics.find(added => added.name === topic.name && topic.id === added.id)
      );
      return filteredTopics;
    },
    async create() {
      const feedbackTopics = this.addedTopics.map(topic => ({
        topicId: topic.id,
        grade: topic.grade,
        comment: topic.comment,
        feedbackTopicTags: topic.feedbackTopicTags
      })); // information from all topics is collected to one array of objects to add to the Feedback

      feedbackTopics.push({
        topicId: this.englishID,
        grade: this.english.grade,
        comment: this.english.comment
      }); // English info is collected and added as simple topic to the feedback

      this.dataToSend.interviewId = this.interviewId;
      this.dataToSend.generalComment = this.generalComment;
      this.dataToSend.feedbackTopics = feedbackTopics;

      const topicWithoutGrade = feedbackTopics.find(topic => topic.grade === null);
      if (topicWithoutGrade) {
        alert('You should set grade for each topic');
      } else if (!!this.dataToSend.generalComment === false) {
        alert('General comment is required');
      } else if (!this.candidateRate) {
        alert('You should set recommendations');
      } else {
        await this.$store.dispatch('feedbacks/create', this.dataToSend);
        await this.$store.dispatch('feedbacks/load');
        const feedbackForRecommendation = await this.$store.state.feedbacks.list.find(
          feedback => feedback.interview.id === this.interviewId
        );
        await this.$store.dispatch('recommendations/create', {
          feedbackId: feedbackForRecommendation.id,
          recommendation: this.candidateRate
        });
        await this.$store.dispatch('recommendations/load');
        this.$emit('feedbackCreated', false);
      }
    },
    topicName(topic) {
      return topic.name;
    },
    removeTopic(id) {
      this.addedTopics = _.filter(this.addedTopics, t => t.id !== id);
    }
  },
  created() {
    this.$store.dispatch('topics/load');

    const candidate = this.$store.state.candidates.list.find(
      candidate => candidate.id === +this.candidateId
    );
    this.$data.addedTopics = candidate.position.topics;
  },
  computed: {
    allTopics() {
      return this.$store.state.topics.list;
    },
    englishID() {
      return this.$store.getters['topics/english'].id;
    }
  }
};
</script>

<style scoped>
.feedback-block {
  max-width: 100%;
}
.topic-container {
  width: 100%;
}
.comment-container {
  margin: 10px;
}
.search {
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.english-select {
  width: 100%;
  padding-right: 1em;
  justify-content: space-between;
}
.select {
  width: 55%;
}
</style>
